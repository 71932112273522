/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import { ThemeProvider } from "@material-ui/styles"
import theme from "../globals/Theme"
import {
  Container,
  CssBaseline,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core"
import Footer from "./Footer"
import ContactFab from "./ContactFab"

interface iLayoutProps {}

const Layout: React.FC<iLayoutProps> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const classes = makeStyles({
    root: {
      padding: 0,
    },
    content: {
      [theme.breakpoints.up("md")]: {
        marginTop: 16,
      },
      minHeight: "40vh",
    },
  })()

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.root}>
          <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
          <Container
            className={classes.content}
            maxWidth="lg"
            disableGutters={isSmallScreen}
          >
            <main>{children}</main>
          </Container>
          <ContactFab />
        </div>
        <Footer />
      </ThemeProvider>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
