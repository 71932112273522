import {
  Box,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core"
import { Facebook, WhatsApp, YouTube } from "@material-ui/icons"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

interface iFooterProps {}

interface iStaticQueryResult {
  site: {
    siteMetadata: {
      title?: string
      whatsappLink?: string
      youtubeLink?: string
      facebookLink?: string
      number?: string
      email?: string
    }
  }
}

const Footer: React.FC<iFooterProps> = props => {
  const theme = useTheme()
  const classes = makeStyles({
    footer: {
      marginTop: theme.spacing(8),
      padding: theme.spacing(4, 20, 1, 20),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(4, 4, 1, 4),
      },
      backgroundColor: theme.palette.secondary.main,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      "& *": {
        color: theme.palette.secondary.contrastText,
      },
    },
    grid: {
      minHeight: 120,
    },
    left: {},
    middle: {
      [theme.breakpoints.up("sm")]: {
        textAlign: "center",
      },
    },
    right: {
      [theme.breakpoints.up("sm")]: {
        textAlign: "right",
      },
    },
    copyright: {
      // display: "flex",
      paddingTop: theme.spacing(1),
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    titleBox: {
      paddingLeft: theme.spacing(2),
    },
    linkWrapper: {
      display: "flex",
      flexFlow: "column",
      alignItems: "flex-end",
      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(1),
      },

      [theme.breakpoints.down("sm")]: {
        flexFlow: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexWrap: "wrap",
        "& > *": {
          marginBottom: theme.spacing(0),
          flex: "1 0 50%",
          [theme.breakpoints.down("sm")]: {
            flex: "1 0 100%",
          },
          "&.address": {
            flex: "2 0 100%",
          },
        },
      },
    },
    link: {
      padding: theme.spacing(1, 2),
      borderRadius: theme.shape.borderRadius,
      display: "flex",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 0.5),
      },
    },
    addressWrapper: {
      padding: theme.spacing(1, 2),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 0.5),
      },
    },
  })()

  const data: iStaticQueryResult = useStaticQuery(graphql`
    query contactInfoFooter {
      site {
        siteMetadata {
          title
          whatsappLink
          youtubeLink
          number
          facebookLink
          email
        }
      }
    }
  `)

  const { whatsappLink, number, facebookLink, youtubeLink, email } =
    data.site.siteMetadata

  return (
    <Paper className={classes.footer} elevation={0}>
      <Container maxWidth="lg">
        <Grid container spacing={1} className={classes.grid}>
          <Grid item sm={4} xs={12} className={classes.left}>
            <Box className={classes.titleBox}>
              <Typography display="block" variant="h5" component="h4">
                Sunninghill
              </Typography>
              <Typography display="block" variant="h5" component="h4">
                Christian
              </Typography>
              <Typography display="block" variant="h5" component="h4">
                Academy
              </Typography>
              <Typography display="block" variant="body2" gutterBottom>
                Grow without limits
              </Typography>
            </Box>
            <Box>
              {facebookLink ? (
                <IconButton
                  href={facebookLink}
                  aria-label="facebook"
                  className="footerLink"
                >
                  <Facebook />
                </IconButton>
              ) : null}
              {whatsappLink ? (
                <IconButton
                  href={whatsappLink}
                  aria-label="whatsapp"
                  className="footerLink"
                >
                  <WhatsApp />
                </IconButton>
              ) : null}
              {youtubeLink ? (
                <IconButton
                  href={youtubeLink}
                  aria-label="youtube"
                  className="footerLink"
                >
                  <YouTube />
                </IconButton>
              ) : null}
            </Box>
          </Grid>
          <Grid item sm={4} xs={12} className={classes.middle}>
            <Typography display="block" variant="body1" gutterBottom>
              But Jesus said, “Let the little children come to me and do not
              hinder them, for to such belongs the kingdom of heaven.”
            </Typography>
            <Typography display="block" variant="caption">
              Matthew 19:14
            </Typography>
          </Grid>
          <Grid item sm={4} xs={12} className={classes.right}>
            <Box className={classes.linkWrapper}>
              <a href={`tel:${number}`} className="footerLink">
                <Box className={classes.link}>
                  <Typography>{number}</Typography>
                </Box>
              </a>
              <a href={`mailto:${email}`} className="footerLink">
                <Box className={classes.link}>
                  <Typography>{email}</Typography>
                </Box>
              </a>
              <Box className={`${classes.addressWrapper} address`}>
                <Typography>75 Edison Cres</Typography>
                <Typography>Sunninghill</Typography>
                <Typography>Sandton</Typography>
                <Typography>2191</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.copyright}>
          <Typography
            display="block"
            variant="caption"
            gutterBottom
          >{`Copyright © ${new Date().getFullYear()} Sunninghill Christian Academy`}</Typography>
          <Typography display="block" variant="caption">
            <a href="https://github.com/joshua-ds13" className="creatorName">
              Created by @joshua-ds13
            </a>
          </Typography>
        </Box>
      </Container>
    </Paper>
  )
}

export default Footer
