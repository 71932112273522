import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
  Button,
  makeStyles,
  Typography,
  useTheme,
  Box,
  Paper,
  Grid,
  Container,
  Divider,
} from "@material-ui/core"
import Modal from "./Modal"
import { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

interface iHeaderProps {
  siteTitle?: string
}

const Header: React.FC<iHeaderProps> = ({ siteTitle }) => {
  const theme = useTheme()
  const classes = makeStyles({
    root: {
      position: "relative",
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
    },
    buttonWrapper: {
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
    menuButtonWrapper: {
      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
    },
    menubar: {
      textAlign: "center",
    },
    menu: {
      display: "inline-block",
      margin: "auto",
      padding: theme.spacing(1, 0),
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
    topBanner: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      textAlign: "center",
      borderRadius: 0,
      color: theme.palette.primary.contrastText,
    },
    formatLink: {
      textDecoration: "none",
      color: "inherit",
    },
    logo: {
      width: 290,
      height: "auto",
    },
    header: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexFlow: "row wrap",

      "& > .titleWrapper": {
        textAlign: "center",
        maxWidth: 500,
        "& > *": {
          fontWeight: theme.typography.fontWeightBold,
        },
      },
    },
  })()

  const [menuOpen, toggleMenu] = useState(false)

  return (
    <Box className={classes.root}>
      <Grid container spacing={0}>
        <Grid key="topbanner" item xs={12}>
          <Paper className={classes.topBanner} elevation={0}>
            <Typography>Come visit us! Every day is an open day.</Typography>
          </Paper>
        </Grid>
        <Grid key="header" item xs={12}>
          <Paper elevation={0} className={classes.header}>
            <StaticImage
              src="../images/logo.png"
              alt="logo"
              className={classes.logo}
              placeholder="blurred"
            />
            <div className="titleWrapper">
              <Typography
                variant="h2"
                display="inline"
                color="primary"
                component="h1"
              >
                {siteTitle}
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Container className={classes.menubar}>
            <Divider />
            <div className={classes.menu}>
              <Link to="/" className={classes.formatLink}>
                <Button size="large" color="primary">
                  Home
                </Button>
              </Link>
              <Link to="/aboutus" className={classes.formatLink}>
                <Button size="large" color="primary">
                  About Us
                </Button>
              </Link>
              <Link to="/faithstatement" className={classes.formatLink}>
                <Button size="large" color="primary">
                  Faith Statement
                </Button>
              </Link>
              <Link to="/contactus" className={classes.formatLink}>
                <Button size="large" color="primary">
                  Contact Us
                </Button>
              </Link>
            </div>
            <Divider />
          </Container>
        </Grid>
      </Grid>
      <Modal
        title="Menu"
        isOpen={menuOpen}
        onClose={() => toggleMenu(false)}
        slideIn="left"
        fullScreen
      >
        <Box
          className={classes.menuButtonWrapper}
          onClick={() => toggleMenu(false)}
        >
          <Button
            color="inherit"
            size="large"
            fullWidth
            href="#top"
            variant="contained"
          >
            Home
          </Button>
          <Button
            color="inherit"
            size="large"
            fullWidth
            href="#aboutus"
            variant="contained"
          >
            About Us
          </Button>
          <Button
            color="inherit"
            size="large"
            fullWidth
            href="#contactus"
            variant="contained"
          >
            Contact Us
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
