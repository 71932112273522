import { makeStyles } from "@material-ui/core"
import { useTheme } from "@material-ui/core"
import {
  ClearAllOutlined,
  EmailOutlined,
  Facebook,
  PhoneInTalkOutlined,
  PhoneOutlined,
  WhatsApp,
} from "@material-ui/icons"
import { SpeedDial, SpeedDialAction } from "@material-ui/lab"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"

interface iContactFabProps {}
interface iStaticQueryResult {
  site: {
    siteMetadata: {
      title?: string
      whatsappLink?: string
      youtubeLink?: string
      facebookLink?: string
      number?: string
      email?: string
    }
  }
}

const ContactFab: React.FC<iContactFabProps> = () => {
  const theme = useTheme()
  const classes = makeStyles({
    root: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })()

  const data: iStaticQueryResult = useStaticQuery(graphql`
    query contactInfo {
      site {
        siteMetadata {
          title
          whatsappLink
          youtubeLink
          number
          facebookLink
          email
        }
      }
    }
  `)

  const [open, toggleOpen] = React.useState<boolean>(false)
  const { ...contacts } = data?.site?.siteMetadata

  const handleActionClick = (type: "wa" | "phone" | "email" | "fb") => {
    let link: string | undefined = ""
    switch (type) {
      case "email":
        link = `mailto:${contacts.email}`
        break
      case "phone":
        link = `tel:${contacts.number}`
        break
      case "wa":
        link = contacts.whatsappLink
        break
      case "fb":
        link = contacts.facebookLink
        break
      default:
        break
    }
    if (link) {
      const clickLink = document.createElement("a")
      clickLink.href = link
      clickLink.click()
    }
  }

  return (
    <SpeedDial
      ariaLabel="Contact us"
      className={classes.root}
      open={open}
      onClose={() => toggleOpen(false)}
      onOpen={() => toggleOpen(true)}
      direction="up"
      icon={<PhoneOutlined />}
    >
      <SpeedDialAction
        key="Phone"
        icon={<PhoneInTalkOutlined />}
        tooltipTitle="Call"
        onClick={() => handleActionClick("phone")}
        tooltipOpen
        aria-label="Call"
        className="speedDialLink"
      />
      <SpeedDialAction
        key="Email"
        icon={<EmailOutlined />}
        tooltipTitle="Email"
        onClick={() => handleActionClick("email")}
        tooltipOpen
        aria-label="Email"
        className="speedDialLink"
      />
      <SpeedDialAction
        key="Form"
        icon={<WhatsApp />}
        tooltipTitle="Whatsapp"
        onClick={() => handleActionClick("wa")}
        tooltipOpen
        aria-label="Whatsapp"
        className="speedDialLink"
      />
      <SpeedDialAction
        key="Form"
        icon={<Facebook />}
        tooltipTitle="Facebook"
        onClick={() => handleActionClick("fb")}
        tooltipOpen
        aria-label="Facebook"
        className="speedDialLink"
      />
    </SpeedDial>
  )
}

export default ContactFab
