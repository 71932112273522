import { createTheme, responsiveFontSizes } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"

const theme = createTheme({
  palette: {
    // type: "dark",
    primary: {
      main: "#255BA6",
    },
    secondary: {
      main: "#5B964D", //023047
    },
  },
  shape: {
    borderRadius: 9,
  },
  typography: {
    button: {
      fontWeight: 600,
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: "none",
        fontWeight: 600,
        fontSize: "1.1rem",
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          scrollBehavior: "smooth",
          WebkitFontSmoothing: "auto",
        },
        body: {
          position: "relative",
          overflowX: "hidden",
          overflowY: "auto",
          width: "100%",
          "&::-webkit-scrollbar": {
            width: 6,
            height: 0,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: grey[600],
          },
        },
      },
    },
  },
})

export default responsiveFontSizes(theme)
