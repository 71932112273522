import React from "react"
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core"
import { DialogTitle } from "@material-ui/core"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { Close } from "@material-ui/icons"

interface iModalProps {
  isOpen: boolean
  onClose?: () => void
  slideIn?: "left" | "right" | "up" | "down"
  fullScreen?: boolean
  title?: string
}

const Transition = React.forwardRef(function Transition(
  transProps: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction={"left"} ref={ref} {...transProps} />
})

const Modal: React.FC<iModalProps> = props => {
  const theme = useTheme()
  const classes = makeStyles({
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(1.5, 2.5),
    },
    contentSpacing: {
      paddingTop: theme.spacing(12),
    },
  })()
  return (
    <Dialog
      open={props.isOpen}
      fullWidth
      maxWidth="sm"
      fullScreen={props.fullScreen}
      onClose={props.onClose}
      {...(props.slideIn
        ? { TransitionComponent: Transition, keepMounted: true }
        : {})}
    >
      {!props.fullScreen ? (
        <DialogTitle>{props.title}</DialogTitle>
      ) : (
        <AppBar>
          <Toolbar className={classes.toolBar}>
            <Typography variant="h4">{props.title}</Typography>
            <IconButton color="inherit" onClick={props.onClose}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <DialogContent
        className={props.fullScreen ? classes.contentSpacing : undefined}
      >
        {props.children}
      </DialogContent>
      {!props.fullScreen ? (
        <DialogActions>
          <Button onClick={props.onClose}>Close</Button>
        </DialogActions>
      ) : null}
    </Dialog>
  )
}

export default Modal
